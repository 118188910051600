import { ProviderInterface } from './../../../../model/user/provider-interface';
import { HeaderServiceService } from 'src/app/services/header-service/header-service.service';
import { UserService } from 'src/app/services/user-service/userservice';
import { Component, OnInit, Input,  ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import {DialogService} from "../../../../services/dialog-service/dialog-service.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  providers$ : Observable<ProviderInterface[]>;
  activeProvider$ : Observable<ProviderInterface>;
  activeProvider: ProviderInterface;
  login_activated = false;

  @Input()
  loggedIn: boolean;

  constructor(
    public router: Router,
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private headerService: HeaderServiceService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.providers$ = this.headerService.providersObservable$;
    this.activeProvider$ = this.headerService.activeProviderObservable$;
    this.headerService.activeProviderObservable$.subscribe((activeProvider) => {
      this.activeProvider = activeProvider;
    })
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        setTimeout(() => {
          this.login_activated = true;
          this.cd.detectChanges();
        }, 2000);
      }
    };
  }

  //---------Buttons----------------------------------------------------------
  login(): void {
    this.router.navigate(["login"]);
  }

  logout(): void {
    this.userService.logout();
  }

  register(): void {
    this.router.navigate(["registry"]);
  }

  listing(): void {
    this.dialogService.openListingRequestDialog();
  }

  openMyProducts() {
    this.router.navigate(["meineProdukte"]);
  }

  openComposer() {
    this.router.navigate(["composer"]);
  }
  openGaiaX() {
    this.router.navigate(["select-file"]);
  }
}
