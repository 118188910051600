import { DatePipe } from '@angular/common';
import { OrganisationDetailResolver } from './resolvers/organisation-detail-resolver';
import { NgModule } from "@angular/core";
import { Title, HammerModule } from "@angular/platform-browser";
// Custom Modules
import { CustomMaterialModule } from "./customModules/app-material-module";
import { AppRoutingModule } from "./customModules/app-routing.module";
import { CustomComponentModule } from "./customModules/app-component-module";
import { CustomViewModule } from "./customModules/app-view-module";
import { CustomPipeModule } from "./customModules/app-pipe-module";
//Services
import { UserService } from "./services/user-service/userservice";
import { UtilService } from "./services/util-service/utilService";
import { OrganisationService } from "./services/dataServices/organisation-service/organisationservice";
import { AuthGuardService } from "./services/auth-guard-service/authGuardService";
import { AuthService } from "./services/auth-service/auth.service";

import { ProductService } from "./services/dataServices/product-service/productService";

import { HeaderServiceService } from "./services/header-service/header-service.service";

import { AppComponent } from "./app.component";
//Interceptors
import { httpInterceptorProviders } from "./Interceptors/InterceptorArray";
import { DragulaModule } from "ng2-dragula";
import { QuillModule } from "ngx-quill";
import { BarChartModule } from "@swimlane/ngx-charts";
import { PtgEditResolver } from "./resolvers/ptg-edit-resolver";
import { PtgListResolver } from "./resolvers/ptg-list-resolver";
import { ProductListResolver } from "./resolvers/product-list-resolver";
import { DocumentListResolver } from "./resolvers/document-list-resolver";
import { DocumentService } from "./services/dataServices/document-service/document.service";
import { ReviewService } from "./services/review-service/review.service";
import { PtgService } from "./services/dataServices/ptg-service/ptgService";
import { AttributeService } from "./services/dataServices/attribute-service/attributeService";
import { AttributeValidationServiceService } from "./services/dataServices/dataValidation/attribute-validation-service/attribute-validation-service.service";
import { SidemenuServiceService } from "./services/componentServices/sidemenu-service/sidemenu-service.service";
import { NavbarService } from "./services/componentServices/navbar-service/navbar-service";
import { MarketplaceFilterService } from "./services/componentServices/marketplace-filter-service/marketplace-filter.service";
import { OrganisationResolver } from "./resolvers/organisation-resolver";
import { VariantResolver } from "./resolvers/variant-resolver";
import { LoadingResolver } from "./resolvers/loading-resolver";
import { PtgVersionResolver } from "./resolvers/ptg-version-resolver";
import { VariantVersionResolver } from "./resolvers/variant-version-resolver";
import { MarketplaceProductResolver } from "./resolvers/marketplace-product-resolver";
import { CertificationListResolver } from "./resolvers/certification-list-resolver";
import { CertificationDocumentListResolver } from "./resolvers/certification-document-list-resolver";
import { CertificationService } from "./services/dataServices/certification-service/certificationService";
import { CertificationVariantVersionService } from "./services/dataServices/cetrification-variant-version-service/certification-variant-version-service";
import { CertificationVariantService } from "./services/dataServices/certification-variant-service/certification-variant-service";
import { CertificationVariantResolver } from "./resolvers/certification-variant-resolver";
import { CertificationVariantVersionResolver } from "./resolvers/certification-variant-version-resolver";
import { MalfunctionService } from "./services/malfunction-service/malfunction.service";
import { ProductlistService } from "./services/productlist-service/productlist.service";
import { MarketplaceReviewService } from "./services/componentServices/marketplace-review-service/marketplace-review.service";
import { SecureImageComponent } from "./components/miscellaneous/secure-image/secure-image.component";
import { CertificationRequestService } from './services/dataServices/certification-request/certification-request.service';
import { ImageSliderComponent } from './components/image-slider/image-slider.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TrackingService } from './services/tracking-service/tracking.service';
import {PtgForProductVariantVersionResolver} from "./resolvers/PtgForProductVariantVersionResolver";



@NgModule({
    declarations: [AppComponent],
    imports: [
        //Custom-Modules
        CustomMaterialModule,
        AppRoutingModule,
        CustomComponentModule,
        CustomViewModule,
        CustomPipeModule,
        DragulaModule,
        BarChartModule,
        QuillModule.forRoot(),
        HammerModule,
        BrowserAnimationsModule,
    ],
    providers: [
        ImageSliderComponent,
        SecureImageComponent,
        ReviewService,
        UserService,
        OrganisationService,
        AuthGuardService,
        AuthService,
        PtgService,
        ProductService,
        AttributeService,
        UtilService,
        AttributeValidationServiceService,
        SidemenuServiceService,
        HeaderServiceService,
        NavbarService,
        httpInterceptorProviders,
        MarketplaceFilterService,
        DocumentService,
        ProductListResolver,
        PtgEditResolver,
        PtgListResolver,
        DocumentListResolver,
        MalfunctionService,
        ProductlistService,
        OrganisationResolver,
        OrganisationDetailResolver,
        VariantResolver,
        LoadingResolver,
        PtgVersionResolver,
        VariantVersionResolver,
        MarketplaceProductResolver,
        CertificationListResolver,
        CertificationDocumentListResolver,
        CertificationService,
        CertificationVariantService,
        CertificationVariantVersionService,
        CertificationVariantResolver,
        CertificationVariantVersionResolver,
        MarketplaceReviewService,
        Title,
        CertificationRequestService,
        DatePipe,
        TrackingService,
        PtgForProductVariantVersionResolver
    ],
    bootstrap: [AppComponent],
    exports: []
})
export class AppModule {}
