<mat-toolbar color="primary" class="mat-elevation-z2">
    <button
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
      *ngIf="isHandset$ | async"
    >
      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <span class="left">
      <a routerLink='/' activatedRoute=""  (click)="this.clickedLogo()"  class="logo mat-elevation-z2"></a>
      <a routerLink='/' activatedRoute=""  (click)="this.clickedLogo()"  class="economlogo mat-elevation-z2"></a>
    </span>
    <span class="middle"> {{ headerText$ | async }} </span>
    <span class="right">
      <app-profile
        [loggedIn]="loggedIn"
      ></app-profile>
    </span>
</mat-toolbar>
<mat-tab-group
  mat-align-tabs="center"
  [color]="'primary'"
  [backgroundColor]="'primary'"
  [(selectedIndex)]="headerService.activeTabIndex"
  (selectedTabChange)="headerService.changedTab($event)"
  *ngIf="(headerText$ | async) != 'Impressum' && (headerText$ | async) != 'Marktplatz' && (headerText$ | async) != 'Datenschutzerklärung' && (headerText$ | async) != 'AGB'"
>
  <mat-tab *ngFor="let menuTab of headerService.getMenuTabs()">
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">category</mat-icon>
      {{ menuTab }}
    </ng-template>
  </mat-tab>
</mat-tab-group>
