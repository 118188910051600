import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { QuillModule } from "ngx-quill";
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { BrowserModule, Title } from "@angular/platform-browser";

//Produktliste
import { ProductListComponent } from "../components/product-list/product-list.component";

import { DigitOnlyDirective } from "../directives/digit-only.directive";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { AttributeTableComponent } from "../components/attributes/attribute-tables/attribute-table/attribute-table.component";
import { CommonFeatureAddComponent } from "../components/common-feature/common-feature-add/common-feature-add.component";
import { CommonFeatureItemEditFormComponent } from "../components/common-feature/common-feature-item-edit-form/common-feature-item-edit-form.component";
import { CommonFeatureItemComponent } from "../components/common-feature/common-feature-item/common-feature-item.component";
import { ComposerProductFilterElementComponent } from "../components/composer/composer-product-filter-element/composer-product-filter-element.component";
import { ComposerProductOverviewComponent } from "../components/composer/composer-product-overview/composer-product-overview.component";
import { DialogAddProductToNodeComponent } from "../components/composer/dialog-add-product-to-node/dialog-add-product-to-node.component";
import { DialogEditMetaDataComponent } from "../components/composer/model-editor/dialog-edit-meta-data/dialog-edit-meta-data.component";
import { ModelEditorComponent } from "../components/composer/model-editor/model-editor.component";
import { DialogCommonFeatureProductCreationComponent } from "../components/dialog-windows/dialog-common-feature-product-creation/dialog-common-feature-product-creation.component";
import { FilemanagerComponent } from "../components/dialog-windows/dialog-file-manager/file-manager.component";
import { DialogRequestComponent } from "../components/dialog-windows/dialog-request/dialog-request.component";
import { DialogCertRequestComponent } from "../components/dialog-windows/dialog-request/dialog-certRequest.component";

import { CertificationCounterComponent } from "../components/icons/certification-counter/certification-counter.component";
import { MalfunctionOverviewOrganisationComponent } from "../components/malfunction/malfunction-overview-organisation/malfunction-overview-organisation.component";
import { MalfunctionOverviewComponent } from "../components/malfunction/malfunction-overview/malfunction-overview.component";
import { ReportMalfunctionScreenComponent } from "../components/malfunction/report-malfunction-screen/report-malfunction-screen.component";
import { CertificationDetailDisplayCriteriaComponent } from "../components/marketplace/detail-pages/certification-detail-display/certification-detail-display-criteria/certification-detail-display-criteria.component";
import { CertificationDetailDisplayOrganisationComponent } from "../components/marketplace/detail-pages/certification-detail-display/certification-detail-display-organisation/certification-detail-display-organisation.component";
import { CertificationDetailDisplayOverviewComponent } from "../components/marketplace/detail-pages/certification-detail-display/certification-detail-display-overview/certification-detail-display-overview.component";
import { CertificationDetailDisplayComponent } from "../components/marketplace/detail-pages/certification-detail-display/certification-detail-display.component";
import { CertificationDetailDisplayCertifiedProductsComponent } from "../components/marketplace/detail-pages/certification-detail-display/certified-products/certification-certified-products.component";
import { CertificationDetailDisplayTypeOverviewComponent } from "../components/marketplace/detail-pages/certification-detail-display/certification-detail-display-type-overview/certification-detail-display-type-overview.component";
import { OrganisationDetailDisplayComponent } from "../components/marketplace/detail-pages/organisation-detail-display/organisation-detail-display.component";
import { ProductDetailDisplayCertificatesComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display-certificates/product-detail-display-certificates.component";
import { ProductDetailDisplayCommonFeaturesComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display-common-features/product-detail-display-common-features.component";
import { ProductDetailDisplayDemoBarComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display-demo-bar/product-detail-display-demo-bar.component";
import { ProductDetailDisplayDownloadsComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display-downloads/product-detail-display-downloads.component";
import { ProductDetailDisplayFeatureComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display-feature/product-detail-display-feature.component";
import { ProductDetailDisplayOrganisationComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display-organisation/product-detail-display-organisation.component";
import { ProductDetailDisplayOverviewComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display-overview/product-detail-display-overview.component";
import { ProductDetailDisplayComponent } from "../components/marketplace/detail-pages/product-detail-display/product-detail-display.component";
import { DetailDisplayAdressDisplayComponent } from "../components/marketplace/detail-pages/subcomponents/detail-display-adress-display/detail-display-adress-display.component";
import { DetailDisplayContactDisplayComponent } from "../components/marketplace/detail-pages/subcomponents/detail-display-contact-display/detail-display-contact-display.component";
import { DetailDisplayDocumentListComponent } from "../components/marketplace/detail-pages/subcomponents/detail-display-document-list/detail-display-document-list.component";
import { DetailDisplayGalleryComponent } from "../components/marketplace/detail-pages/subcomponents/detail-display-gallery/detail-display-gallery.component";
import { DetailDisplayHeaderComponent } from "../components/marketplace/detail-pages/subcomponents/detail-display-header/detail-display-header.component";
import { DetailDisplayVariantVersionSelectorComponent } from "../components/marketplace/detail-pages/subcomponents/detail-display-variant-version-selector/detail-display-variant-version-selector.component";
import { CommentItemComponent } from "../components/marketplace/detail-pages/subcomponents/marketplace-rating-extended/comment-item/comment-item.component";
import { CommentTableComponent } from "../components/marketplace/detail-pages/subcomponents/marketplace-rating-extended/comment-table/comment-table.component";
import { MarketplaceRatingExtendedComponent } from "../components/marketplace/detail-pages/subcomponents/marketplace-rating-extended/marketplace-rating-extended.component";
import { ReviewTableComponent } from "../components/marketplace/detail-pages/subcomponents/marketplace-rating-extended/review-table/review-table.component";
import { CertificateMiniDisplayComponent } from "../components/marketplace/display-item/certificate-mini-display/certificate-mini-display.component";
import { DisplayItemComponent } from "../components/marketplace/display-item/display-item.component";
import { MarketplaceOverviewComponent } from "../components/marketplace/marketplace-overview.component";
import { HmtlDisplayComponent } from "../components/miscellaneous/hmtl-display/hmtl-display.component";
import { ProfilePictureComponent } from "../components/miscellaneous/profile-picture/profile-picture.component";
import { TextEditorComponent } from "../components/miscellaneous/text-editor/text-editor.component";
import { OrganisationContactOverviewComponent } from "../components/organisation-user-settings/views-organisation-management/contact-components/organisation-contact-overview/organisation-contact-overview.component";
import { VersionEditComponent } from "../components/product-certification/shared-subcomponents/version-edit/version-edit.component";
import { PasswordStrengthComponent } from "../components/registration-login/password-strength/password-strength.component";
import { FileDropDirective } from "../directives/file-drop.directive";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DragulaModule } from "ng2-dragula";
import { AttributeCatalogueComponent } from "../components/attributes/attribute-tables/attribute-catalogue/attribute-catalogue.component";
import { AttributeSelectTableComponent } from "../components/attributes/attribute-tables/attribute-select-table/attribute-select-table.component";
import { AttributeValidationTableComponent } from "../components/attributes/attribute-tables/attribute-validation-table/attribute-validation-table.component";
import { PtgAttributeCatalogueComponent } from "../components/attributes/attribute-tables/ptg-attribute-catalogue/ptg-attribute-catalogue.component";
import { AttributeValidationComponent } from "../components/attributes/attribute-validation/attribute-validation.component";
import { HexLabelButtonComponent } from "../components/buttons/hex-label-button/hex-label-button.component";
import { HexagonButtonTextIconComponent } from "../components/buttons/hexagon-button-text-icon/hexagon-button-text-icon.component";
import { RedCrossButtonComponent } from "../components/buttons/red-cross-button/red-cross-button.component";
import { RoundedButtonComponent } from "../components/buttons/rounded-button/rounded-button.component";
import { ToggleSwitchComponent } from "../components/buttons/toggle-switch/toggle-switch.component";
import { DialogAttributeCreate } from "../components/dialog-windows/dialog-attribute-create";
import { DialogConfirmComponent } from "../components/dialog-windows/dialog-confirm.component";
import { DialogFileManagerComponent } from "../components/dialog-windows/dialog-filemanager.component";
import { DialogOverviewExampleDialogComponent } from "../components/dialog-windows/dialog-overview-example-dialog.component";
import { DialogRemoveMemberConfirmComponent } from "../components/dialog-windows/dialog-remove-member-confirm/dialog-remove-member-confirm.component";
import { DialogText } from "../components/dialog-windows/dialog-text";
import { DialogAgb } from "../components/dialog-windows/dialog-agb-text/dialog-agb";
import { DialogBaustelleRequestComponent } from "../components/dialog-windows/dialog-request/dialog-baustelle-request.component"


import { DialogPrivacy } from "../components/dialog-windows/dialog-privacy-text/dialog-privacy";

import { TriangleIndentComponent } from "../components/icons/triangle-indent/triangle-indent.component";
import { ValidationIconComponent } from "../components/icons/validation-icon/validation-icon.component";
import { WriteCommentComponent } from "../components/marketplace/detail-pages/subcomponents/marketplace-rating-extended/write-comment/write-comment.component";
import { InfiniteScrollComponent } from "../components/marketplace/subcomponents/infinite-scroll/infinite-scroll.component";
import { MarketplaceCategorySwitchComponent } from "../components/marketplace/subcomponents/marketplace-category-switch/marketplace-category-switch.component";
import { MarketplaceProductRondellComponent } from "../components/marketplace/subcomponents/marketplace-product-rondell/marketplace-product-rondell.component";
import { MarketplaceProducthexagonComponent } from "../components/marketplace/subcomponents/marketplace-producthexagon/marketplace-producthexagon.component";
import { MarketPlaceSideFilterComponent } from "../components/marketplace/subcomponents/marketplace-side-filter/market-place-side-filter.component";
import { MarketplaceSideimagehexagonComponent } from "../components/marketplace/subcomponents/marketplace-sideimagehexagon/marketplace-sideimagehexagon.component";
import { FooterComponent } from "../components/miscellaneous/footer/footer.component";
import { HexLabelItemComponent } from "../components/miscellaneous/hex-label-item/hex-label-item.component";
import { HexagonComponent } from "../components/miscellaneous/hexagon/hexagon.component";
import { SecureImageComponent } from "../components/miscellaneous/secure-image/secure-image.component";
import { WelcomeComponent } from "../components/miscellaneous/welcome/welcome.component";
import { NavButtonsComponent } from "../components/navbar/nav-buttons/nav-buttons.component";
import { NavIconComponent } from "../components/navbar/nav-icon/nav-icon.component";
import { NavbarHexagonComponent } from "../components/navbar/navbar-hexagon/navbar-hexagon.component";
import { NavbarComponent } from "../components/navbar/navbar/navbar.component";
import { CreateContactFormComponent } from "../components/organisation-user-settings/views-organisation-management/contact-components/create-contact-form/create-contact-form.component";
import { AddEntriesToListComponent } from "../components/product-certification/shared-subcomponents/addEntriesToList-panel/addEntriesToList-panel.component";
import { InfoBoxComponent } from "../components/product-certification/shared-subcomponents/info-box/info-box.component";
import { VersionInfoBoxComponent } from "../components/product-certification/shared-subcomponents/version-info-box/version-info-box.component";
import { AddProductDependencyPanelComponent } from "../components/products-ptg-dependencies/add-product-dependency-panel/add-product-dependency-panel.component";
import { AddPtgDependencyPanelComponent } from "../components/products-ptg-dependencies/add-ptg-dependency-panel/add-ptg-dependency-panel.component";
import { SelectedProductPanelComponent } from "../components/products-ptg-dependencies/selected-product-panel/selected-product-panel.component";
import { SelectedPtgPanelComponent } from "../components/products-ptg-dependencies/selected-ptg-panel/selected-ptg-panel.component";
import { ReviewsComponent } from "../components/reviews/reviews/reviews.component";
import { StarComponent } from "../components/reviews/star/star.component";
import { WriteReviewComponent } from "../components/reviews/write-review/write-review.component";
import { FilterPanelComponent } from "../components/sidemenu/components-filterpanel-searchpanel/filter-panel/filter-panel.component";
import { RatingPanelComponent } from "../components/sidemenu/components-filterpanel-searchpanel/rating-panel/rating-panel.component";
import { SearchPanelComponent } from "../components/sidemenu/components-filterpanel-searchpanel/search-panel/search-panel.component";
import { SidemenuButtonComponent } from "../components/sidemenu/sidemenu/sidemenu-button/sidemenu-button.component";
import { SidemenuNewComponent } from "../components/sidemenu/sidemenu/sidemenu-new/sidemenu-new.component";
import { CustomMaterialModule } from "./app-material-module";
import { CustomPipeModule } from "./app-pipe-module";
import { DialogDeleteUserManageOrganisationsComponent } from "../components/dialog-windows/dialog-delete-user-manage-organisations/dialog-delete-user-manage-organisations.component";
import { ToolchainInstanceTableRowComponent } from '../components/composer/composer-overview/toolchain-instance-table-row/toolchain-instance-table-row.component';
import { ToolchainTemplateTableRowComponent } from '../components/composer/composer-overview/toolchain-template-table-row/toolchain-template-table-row.component';
import { ToolchainTemplateTableComponent } from '../components/composer/composer-overview/toolchain-template-table/toolchain-template-table.component';
import { ToolchainInstanceTableComponent } from '../components/composer/composer-overview/toolchain-instance-table/toolchain-instance-table.component';
import { AddSchemaFormComponent } from "../components/compatiblity-features/add-schema-form/add-schema-form.component";
import { ModelViewerComponent } from '../components/composer/model-viewer/model-viewer.component';
import { DialogToolchainInstanceUploadDataComponent } from '../components/composer/dialog-toolchain-instance-upload-data/dialog-toolchain-instance-upload-data.component';
import { ToolchainDetailDisplayComponent } from '../components/marketplace/detail-pages/toolchain-detail-display/toolchain-detail-display.component';
import { PublicActivityItemComponent } from "../components/components-profiles/public-activity-item/public-activity-item.component";
import { PublicReviewComponent } from "../components/components-profiles/public-review/public-review.component";
import { DialogReportReviewComponent } from '../components/dialog-windows/report-review/report-review.component';
import { ReviewReportItemComponent } from '../components/reviews/review-report-item/review-report-item.component';
import { HexaconButtonLeftTextIconComponent } from '../components/buttons/hexacon-button-left-text-icon/hexacon-button-left-text-icon.component';
import { VersionCertificationRequestComponent } from '../components/product-certification/products/version-certification-request/version-certification-request.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CertificationRequestOverviewComponent } from '../components/certification-request-shared/certification-request-overview/certification-request-overview.component';
import { CertificationRequestProcessingComponent } from '../components/organisation-user-settings/views-organisation-management/certification-requests/certification-request-processing/certification-request-processing.component';
import { CertificationRequestLogComponent } from '../components/certification-request-shared/certification-request-log/certification-request-log.component';
import { CertificationRequestLogEntryComponent } from '../components/certification-request-shared/certification-request-log/certification-request-log-entry/certification-request-log-entry.component';
import { VersionCertificationRequestStatusComponent } from './../components/product-certification/products/version-certification-request-status/version-certification-request-status.component';
import { StatusDisplayComponent } from '../components/certification-request-shared/status-display/status-display.component';
import { DialogCertReqChangeStatusComponent } from '../components/dialog-windows/dialog-certReq-change-status.component';
import { DialogDelegateCertificationComponent } from '../components/dialog-windows/dialog-delegate-certification.component';
import { DialogConfirmDelegateCertificationComponent } from '../components/dialog-windows/dialog-confirm-delegate-certification.component';
import { DialogFileRequestComponent } from '../components/dialog-windows/dialog-certReq-requestFile.component';
import { ImpressumComponent } from '../components/impressum/impressum.component';
import { AgbComponent } from '../components/agb/agb.component';

import { PrivacyComponent } from '../components/privacy/privacy.component';

import { ImageSliderComponent } from '../components/image-slider/image-slider.component';
import { HeaderComponent } from '../components/miscellaneous/header/header.component';
import { ProfileComponent } from '../components/miscellaneous/header/profile/profile.component';
import { DialogCookieConsentComponent } from "../components/dialog-windows/dialog-cookie-consent/dialog-cookie-consent.component";
import { ContactFormComponent } from '../components/organisation-user-settings/views-organisation-management/contact-components/contact-form/contact-form.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { ModalModule } from "../gaiax/core/_modal";
import { I18nModule } from "../gaiax/i18n/i18n.module";
import { MaterialModule } from "../gaiax/material.module";
import { AppRoutingModule } from "./app-routing.module";
import { DynamicFormArrayComponent } from "@components/dynamic-form-array/dynamic-form-array.component";
import { DynamicFormInComponent } from "@components/dynamic-form-in/dynamic-form-in.component";
import { DynamicFormInputComponent } from "@components/dynamic-form-input/dynamic-form-input.component";
import { DynamicFormOrArrayComponent } from "@components/dynamic-form-or-array/dynamic-form-or-array.component";
import { DynamicFormOrComponent } from "@components/dynamic-form-or/dynamic-form-or.component";
import { DynamicSelfLoopsComponent } from "@components/dynamic-self-loops/dynamic-self-loops.component";
import { ExpandedFieldsComponent } from "@components/expanded-fields/expanded-fields.component";
import { ShowErrorsComponent } from "@components/show-errors/show-errors.component";
import { NumericDirective } from "@directives/numeric.directive";
import { DynamicFormComponent } from "../gaiax/core/dynamic-form/dynamic-form.component";
import { FileUploadComponent } from "../gaiax/core/file-upload/component/file-upload.component";
import { HomepageComponent } from "../gaiax/core/homepage/homepage.component";
import { SelectFileComponent } from "../gaiax/core/select-file/select-file.component";
import { SelectLanguageComponent } from "../gaiax/core/select-language/select-language.component";
import { SelectShapeComponent } from "../gaiax/core/select-shape/select-shape.component";
import { StartingPageComponent } from "../gaiax/core/starting-page/starting-page.component";
import { ApiService } from "@services/api.service";
import { FormfieldControlService } from "@services/form-field.service";
import { ValidationControlService } from "@services/validation.service";
import { CustomRouteReuseStrategy } from "@shared/custom-route-reuse-strategy";
import { FilesProvider } from "@shared/files-provider";
import { HttpErrorInterceptor } from "../gaiax/http-error.interceptor";
import { ComposerScreenComponent } from "../components/composer/composer-screen/composer-screen.component";
import { CamundaProcessListComponent } from "../components/camunda/camunda-process-list/camunda-process-list.component";
import { WebsocketComponent } from "../components/camunda/web-socket/web-socket.component";
import { CamundaTaskComponent } from "../components/camunda/camunda-task/camunda-task.component";
import { ServiceTaskComponent } from "../components/camunda/service-task/service-task.component";
import { BpmnDiagramComponent } from "../components/camunda/bpmn-diagram-component/bpmn-diagram.component";
import { FileHandlerComponent } from "../components/camunda/file-handler/file-handler.component";
import { Store } from "@ngrx/store";
import { SshComponent } from '../components/EConoM/ssh-console/ssh-console.component';
import { BaustelleDetailDisplayComponent } from "../components/marketplace/detail-pages/product-detail-display/baustelle-detail-display.component";
/* import { PaymentPopupComponent } from '../components/payment/payment-popup/payment-popup.component';
import { PaymentComponent } from '../components/payment/payment.component';
import { AddCardComponent } from '../components/payment/add-card/add-card.component';
import { CardComponent } from '../components/payment/card/card.component';
import { CardsComponent } from '../components/payment/cards/cards.component';
import { TransactionComponent } from '../components/payment/transaction/transaction.component';
import { TransactionsComponent } from '../components/payment/transactions/transactions.component';
import { WalletComponent } from '../components/payment/wallet/wallet.component';
import { CardBalanceComponent } from '../components/payment/card-balance/card-balance.component';
import { TransactionAmountComponent } from '../components/payment/transaction-amount/transaction-amount.component';
import { TransactionTypeIconComponent } from '../components/payment/transaction-type-icon/transaction-type-icon.component'; */
import { ServerDetailDisplayComponent } from "../components/marketplace/detail-pages/product-detail-display/server-detail-display.component";
import { DialogServerRequestComponent } from "../components/dialog-windows/dialog-request/dialog-server-request.component";
import { ServerDetailDisplayDockerComponent } from "../components/marketplace/detail-pages/product-detail-display/server-detail-display-docker/server-detail-display-docker.component";
import { DialogAddProductToBaustelleComponent } from "../components/product-certification/products/products/product-edit-screen/dialog-add-product-to-baustelle/dialog-add-product-to-baustelle.component";
import { BaustelleProductOverviewComponent } from "../components/product-certification/products/products/product-edit-screen/baustelle-product-overview/baustelle-product-overview.component";
import { AddInterfaceDependencyPannelComponent } from "../components/products-ptg-dependencies/add-interface-dependency-pannel/add-interface-dependency-pannel.component";
import { SelectedInterfaceDependencyPannelComponent } from "../components/products-ptg-dependencies/selected-interface-dependency-pannel/selected-interface-dependency-pannel.component";
//St�rungsmanagement
export function filesProviderFactory(provider: FilesProvider) {
  return () => provider.load();
}

@NgModule({
    imports: [
        CommonModule,
        QuillModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CustomMaterialModule,
        CustomPipeModule,
        NgxChartsModule,
        MatButtonToggleModule,
        DragulaModule.forRoot(),
        QuillModule,
        ScrollingModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        HttpClientModule,
        FlexLayoutModule,
        ModalModule,
        I18nModule,
      ],
  declarations: [
/*     //Payment 
    PaymentPopupComponent,
    PaymentComponent,
    AddCardComponent, 
    CardComponent, 
    CardsComponent, 
    TransactionComponent, 
    TransactionsComponent, 
    WalletComponent, 
    CardBalanceComponent, 
    TransactionAmountComponent, 
    TransactionTypeIconComponent, */
    


    
    // CAMUNDA 
    CamundaProcessListComponent,
    WebsocketComponent,
    CamundaTaskComponent,
    ServiceTaskComponent,
    BpmnDiagramComponent,
    FileHandlerComponent,
    SshComponent,
    //GAIA-X Selbstbeschribung Componenets
    DynamicFormComponent,
    DynamicFormInputComponent,
    ShowErrorsComponent,
    DynamicFormArrayComponent,
    NumericDirective,
    DynamicFormOrComponent,
    FileUploadComponent,
    HomepageComponent,
    SelectShapeComponent,
    ExpandedFieldsComponent,
    DynamicFormOrArrayComponent,
    DynamicSelfLoopsComponent,
    DynamicFormInComponent,
    SelectFileComponent,
    StartingPageComponent,
    SelectLanguageComponent,

    //Header
    HeaderComponent,
    ProfileComponent,
    //Components
    ValidationIconComponent,
    AttributeCatalogueComponent,
    PtgAttributeCatalogueComponent,
    AttributeTableComponent,
    WelcomeComponent,
    HexagonComponent,
    AddEntriesToListComponent,
    AttributeSelectTableComponent,
    AttributeValidationTableComponent,
    InfoBoxComponent,
    VersionInfoBoxComponent,
    SelectedPtgPanelComponent,
    AddPtgDependencyPanelComponent,
    AddProductDependencyPanelComponent,
    SelectedProductPanelComponent,
    AttributeValidationComponent,
    DigitOnlyDirective,
    CreateContactFormComponent,
    VersionEditComponent,
    TextEditorComponent,
    FileDropDirective,
    PasswordStrengthComponent,
    //OrganisationEditComponent,
    OrganisationContactOverviewComponent,
    //NavBar
    NavbarComponent,
    NavButtonsComponent,
    NavbarHexagonComponent,
    NavIconComponent,
    //Sidemenu
    SearchPanelComponent,
    RatingPanelComponent,
    FilterPanelComponent,
    HexLabelItemComponent,
    SidemenuNewComponent,
    SidemenuButtonComponent,
    //Buttons
    HexagonButtonTextIconComponent,
    HexLabelButtonComponent,
    TriangleIndentComponent,
    RedCrossButtonComponent,
    ToggleSwitchComponent,
    RoundedButtonComponent,
    HexaconButtonLeftTextIconComponent,
    //Marktplatz
    MarketplaceCategorySwitchComponent,
    MarketplaceProducthexagonComponent,
    MarketplaceSideimagehexagonComponent,
    MarketPlaceSideFilterComponent,
    InfiniteScrollComponent,
    CertificationCounterComponent,
    WriteCommentComponent,
    MarketplaceProductRondellComponent,

    //
    CertificationDetailDisplayComponent,
    CertificationDetailDisplayOverviewComponent,
    CertificationDetailDisplayOrganisationComponent,
    CertificationDetailDisplayCriteriaComponent,
    CertificationDetailDisplayCertifiedProductsComponent,
    CertificationDetailDisplayTypeOverviewComponent,
    StatusDisplayComponent,
    //
    DisplayItemComponent,
    OrganisationDetailDisplayComponent,
    ProductDetailDisplayComponent,
    MarketplaceOverviewComponent,
    CertificateMiniDisplayComponent,
    ReviewTableComponent,
    ReviewReportItemComponent,
    //
    DetailDisplayHeaderComponent,
    DetailDisplayGalleryComponent,
    MarketplaceRatingExtendedComponent,
    CommentItemComponent,
    //
    ProductDetailDisplayOverviewComponent,
    
    ServerDetailDisplayComponent,
    ProductDetailDisplayFeatureComponent,
    ProductDetailDisplayDownloadsComponent,
    ProductDetailDisplayCertificatesComponent,
    ProductDetailDisplayOrganisationComponent,
    ProductDetailDisplayDemoBarComponent,
    ProductDetailDisplayCommonFeaturesComponent,
    DetailDisplayVariantVersionSelectorComponent,
    DetailDisplayDocumentListComponent,
    DetailDisplayContactDisplayComponent,
    OrganisationContactOverviewComponent,
    DetailDisplayAdressDisplayComponent,


    // EConoM Demo 10
    BaustelleDetailDisplayComponent,
    BaustelleProductOverviewComponent,
    ServerDetailDisplayDockerComponent,
    DialogAddProductToBaustelleComponent,
    DialogBaustelleRequestComponent,
    //
    ToolchainDetailDisplayComponent,
    //Bewertung
    WriteReviewComponent,
    StarComponent,
    ReviewsComponent,
    CommentTableComponent,
    WriteReviewComponent,
    StarComponent,
    CommentItemComponent,

    //Divers
    HmtlDisplayComponent,
    //Footer
    FooterComponent,
    //Dialog
    DialogOverviewExampleDialogComponent,
    DialogAttributeCreate,
    DialogText,
    DialogAgb,
    DialogPrivacy,
    DialogFileManagerComponent,
    DialogRemoveMemberConfirmComponent,
    DialogConfirmComponent,
    DialogCommonFeatureProductCreationComponent,
    DialogRequestComponent,
    
    DialogServerRequestComponent,
    DialogCertRequestComponent,
    DialogAddProductToNodeComponent,
    DialogEditMetaDataComponent,
    DialogDeleteUserManageOrganisationsComponent,
    DialogToolchainInstanceUploadDataComponent,
    DialogCertReqChangeStatusComponent,
    DialogDelegateCertificationComponent,
    DialogConfirmDelegateCertificationComponent,
    DialogFileRequestComponent,
    DialogCookieConsentComponent,

    //SecureImage
    SecureImageComponent,

    ImageSliderComponent,
    ImpressumComponent,
    AgbComponent,
    PrivacyComponent,
    FilemanagerComponent,

    //Produktlist
    ProductListComponent,

    //Stoerungsmanagement
    MalfunctionOverviewComponent,
    ReportMalfunctionScreenComponent,
    MalfunctionOverviewOrganisationComponent,

    //Zertifizierung
    CertificationCounterComponent,
    VersionCertificationRequestComponent,
    VersionCertificationRequestStatusComponent,
    CertificationRequestLogComponent,
    CertificationRequestLogEntryComponent,
    CertificationRequestOverviewComponent,
    CertificationRequestProcessingComponent,

    //Misc
    ProfilePictureComponent,

    //Common Features
    CommonFeatureAddComponent,
    CommonFeatureItemComponent,
    CommonFeatureItemEditFormComponent,
    //Composer
    ModelEditorComponent,
    ComposerProductFilterElementComponent,
    ComposerProductOverviewComponent,
    ToolchainInstanceTableRowComponent,
    ToolchainTemplateTableRowComponent,
    ToolchainTemplateTableComponent,
    ToolchainInstanceTableComponent,
    ModelViewerComponent,

    // Compatiblity Service
    AddSchemaFormComponent,
    //Public Profile
    PublicActivityItemComponent,
    PublicReviewComponent,
    ContactFormComponent
  ],
  exports: [
    //Header
    HeaderComponent,
    ProfileComponent,
    //Modules
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CustomMaterialModule,
    CustomPipeModule,

    //Components
    ImageSliderComponent,
    ImpressumComponent,
    AgbComponent,
    PrivacyComponent,
    SecureImageComponent,
    ValidationIconComponent,
    AttributeCatalogueComponent,
    PtgAttributeCatalogueComponent,
    AttributeTableComponent,
    WelcomeComponent,
    AttributeSelectTableComponent,
    AttributeValidationTableComponent,
    InfoBoxComponent,
    VersionInfoBoxComponent,
    SelectedPtgPanelComponent,
    AddPtgDependencyPanelComponent,
    AddEntriesToListComponent,
    AddProductDependencyPanelComponent,
    SelectedProductPanelComponent,
    HexagonComponent,
    PasswordStrengthComponent,
    ReviewReportItemComponent,
    //FileManagementSystem
    FilemanagerComponent,
    AttributeValidationComponent,
    DigitOnlyDirective,
    CreateContactFormComponent,
    VersionEditComponent,
    TextEditorComponent,
    //OrganisationEditComponent,
    OrganisationContactOverviewComponent,
    //NavBar
    NavbarComponent,
    NavButtonsComponent,
    NavbarHexagonComponent,
    NavIconComponent,
    //Sidemenu
    SearchPanelComponent,
    RatingPanelComponent,
    FilterPanelComponent,
    HexLabelItemComponent,
    SidemenuNewComponent,
    SidemenuButtonComponent,
    //Buttons
    HexagonButtonTextIconComponent,
    HexLabelButtonComponent,
    TriangleIndentComponent,
    RedCrossButtonComponent,
    ToggleSwitchComponent,
    RoundedButtonComponent,
    //Marktplatz
    MarketplaceProducthexagonComponent,
    MarketplaceSideimagehexagonComponent,
    MarketPlaceSideFilterComponent,
    InfiniteScrollComponent,
    DragulaModule,
    CertificationCounterComponent,
    WriteCommentComponent,
    WriteReviewComponent,
    StarComponent,
    CommentItemComponent,
    DisplayItemComponent,
    HmtlDisplayComponent,
    //Footer
    FooterComponent,

    //Stoerungsmanagement
    MalfunctionOverviewComponent,
    OrganisationContactOverviewComponent,
    //Misc
    ProfilePictureComponent,

    //Common Feature
    CommonFeatureAddComponent,
    CommonFeatureItemComponent,
    CommonFeatureItemEditFormComponent,
    //Composer
    ModelEditorComponent,
    ComposerProductFilterElementComponent,
    ComposerProductOverviewComponent,
    ToolchainInstanceTableRowComponent,
    ToolchainTemplateTableRowComponent,
    ToolchainTemplateTableComponent,
    ToolchainInstanceTableComponent,
    
    // Compatiblity Service
    AddSchemaFormComponent,
    ModelViewerComponent,

    //Public Profile
    PublicActivityItemComponent,
    PublicReviewComponent,

    //Certification
    StatusDisplayComponent,
    ContactFormComponent,

    CamundaProcessListComponent,
    WebsocketComponent,
    CamundaTaskComponent,
    ServiceTaskComponent,
    BpmnDiagramComponent,
    FileHandlerComponent,
    SshComponent,
  ],
  providers: [FormfieldControlService, ValidationControlService, ApiService, DatePipe, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
    FilesProvider, {provide: APP_INITIALIZER, useFactory: filesProviderFactory, deps: [FilesProvider], multi: true},
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy
    }
  ],
})
export class CustomComponentModule {}
