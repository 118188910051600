import { TrackingService } from './../../tracking-service/tracking.service';
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";

import { ProductInterface } from "../../../model/products/product-interface";

import { UserService } from "../../user-service/userservice";

import { Status } from "src/app/model/enums/status";

import { SortMethod } from "src/app/model/enums/SortMethod";

import { BaseService } from "../../base.service";

import { ProductVariantService } from "../product-variant-service/product-variant.service";
import { ProductVariantInterface } from "src/app/model/products/product-variant-interface";
import { OrganisationInterface } from "src/app/model/organisations/organisation-interface";
import { map } from "rxjs/operators";
import { SidemenuServiceService } from "../../componentServices/sidemenu-service/sidemenu-service.service";
import { ProductDtoConverterServiceService } from "../../product-dto-converter-service/product-dto-converter-service.service";
import { FileUploadService } from "../file-upload-service/file-upload.service";
import { Observable, interval } from "rxjs";
import { API_MAP, API_BASE_PRODUCTSERVICE } from "src/environments/api";
import { ProductMinimalInterface } from "src/app/model/products/product-minimal-interface";
import { CertificationVariantVersionService } from "../cetrification-variant-version-service/certification-variant-version-service";
import { RequestInterface } from "src/app/model/marketplace/request-interface";
import { ProductOverview } from 'src/app/model/product-overview/product-overview';
import {ListingRequestInterface} from "../../../model/ListingRequestInterface";
import { Baustelle } from 'src/app/model/products/baustelle-interface';

//(import { DocumentInterface } from '../model/documents/document-interface';

const SWARM_API = API_BASE_PRODUCTSERVICE;
const SWARM_PIC_API = "/proxy/api/v0/productservice/pictures/";
const SWARM_DOC_API = "/proxy/api/v0/productservice/documents/";


const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
  }),
};

@Injectable()
export class ProductService extends BaseService<ProductInterface> {

  //IDs der zu löschenden Inhalte des Filemanagers
  public toBeDeletedIds : string[] = [];

  /**
   * @param item
   * @returns -1 if product has no id
   */
  protected toID(item: ProductInterface | number): number {
    if (typeof item === "number") return item;
    if (item.id) return item.id;
    return -1;
  }

  constructor(
    protected dtoConverter: ProductDtoConverterServiceService,
    protected fileUploadService: FileUploadService,
    protected certificationVersionService: CertificationVariantVersionService,
    protected http: HttpClient,
    private variantService: ProductVariantService,
    private userService: UserService,
    private trackingService: TrackingService
  ) {
    super(http, dtoConverter);
    //set the swarm API
    this.SWARM_API_BASE = SWARM_API;
    //-----------------------
    // Specify a item mapping to know which URL identifier is to be mapped
    // to witch information on an item
    this.ITEM_MAP = this.userService.ACTIVE_MAP;
    // Setting coded URL path for the API of this service
    this.SERVICE_FLAG = ":productId";
    this.SWARM_API_MAP = API_MAP["products"];

    //--------------------------------------------------------------------------
    this.variantService.deleteRequestObserver.subscribe(
      (toBeDeleted: ProductVariantInterface) => {
        if (this.activeItem && this.activeItem.productVariants) {
          const index: number = this.activeItem.productVariants.findIndex(
            (x) => {
              return x.id === toBeDeleted.id;
            }
          );
          if (index >= 0) {
            this.activeItem.productVariants.splice(index, 1);
          }
        }
      }
    );
    //--------------------------------------------------------------------------
    // Subscribe to the variant service so this instance of product is updated
    // this ensures that there is no unnecessary traffic to update
    this.variantService.activeItemObserver.subscribe(
      (variant: ProductVariantInterface) => {
        if (!variant || typeof variant === "undefined") {
          return;
        }

        if (variant) {
          // The active Item is always the current product that is edited
          // see if variant is already in product
          let index = this.activeItem.productVariants.findIndex(
            (x) => x.id === variant.id
          );
          if (index > -1) {
            // there is variant with same ID

            // but the variant was edited so we override the reference
            // in the active product with the new variant
            this.activeItem.productVariants[index] = variant;
          } else {
            // variant is not in product so add it
            this.activeItem.productVariants.push(variant);
          }
          // we update the active item in collection
          this.updateItemInCollection(this.activeItem);
          //
        }
        this.next();
      }
    );
    //--------------------------------------------------------------------------
    // Subscribe to the certificat variant version service so this instance
    // of product is updated when a new certification is added to a product
    // this ensures that there is no unnecessary traffic to update
    //--------------------------------------------------------------------------
    this.certificationVersionService.addedCertificationObserver.subscribe(
      (productId) => {
        if (productId) {
          this.reload(productId);
        }
      }
    );
    //--------------------------------------------------------------------------
    this.activeItemObserver.subscribe(() => {
      // router.navigate();
    });
    //--------------------------------------------------------------------------
  }

  get activeProduct() {
    return this.activeItem;
  }

  get products() {
    return this.collection;
  }

  getProdctByName(name: string): ProductInterface {
    return this.collection.find((x) => {
      return x.name === name;
    });
  }

  setActiveProduct(product: ProductInterface | number): Promise<boolean> {
    let id = this.toID(product);
    this.userService.ACTIVE_MAP[":productId"] = String(id);
    return this.setActiveItem(id);
  }

  getProductsOfOrganisation(organisationId: number) {
    return this.collection.filter(
      (product) => product.company === organisationId
    );
  }

  getProduct(product: ProductInterface | number) {
    this.ITEM_MAP[":productId"] = String(this.toID(product));
    return this.getItem(product);
  }

  /**
   * If through deletion of a file (process within file-manager component) one or more fields
   * of a product point to a no longer existing file this function deletes the references
   * @param productId the productId that was connected to a now deleted file via object link
   * @param type the kind of connection the object link held to the now deleted file
   * @param fid fileId of the now deleted file
   */
  deleteFileReference(productId: number, type: string, fid?: string) {
    for(let i = 0; i < this.collection.length; i++) {
      if(this.collection[i].id == productId) {
        if(type == "product-picture") {
          this.collection[i].fileId = null;
          return;
        }
        if(type == "gallery-picture") {
          let index = this.collection[i].galleryFileIds.findIndex((fileId: string) => fileId == fid);
          if (index >= 0) {
            this.collection[i].galleryFileIds.splice(index, 1);
          }
          return;
        }
        if(type == "product-doc") {
          let index = this.collection[i].documentFileIds.findIndex((fileId: string) => fileId == fid);
          if (index >= 0) {
            this.collection[i].documentFileIds.splice(index, 1);
          }
        }
      }
    }
  }

  async createProduct(product: ProductInterface) {
    this.ITEM_MAP[":productId"] = String(this.toID(product));
    return this.postItem(product);
  }

  async editProduct(product: ProductInterface, file?: File) {
    this.ITEM_MAP[":productId"] = String(this.toID(product));
    if (file) {
      let f = await this.uploadProductLogo(file);
      product.pictureId = f;
    }

    return this.putItem(product);
  }

  /**
   *
   * @param product
   */
  loadProductsForOrganisation(
    organisation: OrganisationInterface
  ): Promise<boolean> {
    let orgURL =
      API_BASE_PRODUCTSERVICE +
      API_MAP["products"]["OF_ORGANISATION"].replace(
        ":organisationId",
        "" + organisation.organisationId
      );
    return new Promise((resolve, reject) => {
      this.http.get<ProductInterface[]>(orgURL).subscribe(
        //success
        (productDTO) => {
          let ids: Promise<void>[] = [];
          productDTO.forEach(async (element) => {
            ids.push(
              this.getProduct(element.id).then((x) => {
                this.updateVariantService(x);
              })
            );

            await ids[ids.length - 1];
          });

          Promise.all(ids).then(() => {
            resolve(true);
          });
        },
        //failure
        () => {
          reject(false);
        }
      );
    });
  }
  /**
   *  Downward propagation to update new loaded items into the subservice
   * @param product
   */
  updateVariantService(product: ProductInterface) {
    product.productVariants.forEach((variant: ProductVariantInterface) =>
      this.variantService.updateVariantService(variant)
    );
  }

  deleteVariantsIn(product: ProductInterface) {
    product.productVariants.forEach((variant) => {
      this.variantService.deleteVersionsIn(variant);
    });
    let index = this.collection.findIndex((x) => this.idCompare(x, product));
    if (index >= 0) {
      this.collection.splice(index, 1);
    }
  }

  deleteCurrentProduct() {
    this.ITEM_MAP[":productId"] = String(this.activeItem.id);
    return this.deleteProduct(this.activeItem);
  }

  deleteProduct(product: ProductInterface) {
    return this.deleteItem(product).then((success) =>
      this.deleteVariantsIn(product)
    );
  }

  uploadProductLogo(productLogo: File): Promise<number> {
    let productId: number = this.activeItem.id;
    var url =
      API_BASE_PRODUCTSERVICE +
      API_MAP["productPictures"]["POST"].replace(":entityId", productId + "");

    return new Promise((resolve, reject) => {
      this.fileUploadService.uploadFile(productLogo, url).subscribe(
        //success
        (x: HttpResponse<string>) => {
          resolve(parseInt(x.body));
        },
        //failure
        () => {
          reject(-1);
        }
      );
    });
  }

  /**
   * Uploads the given File to the picture Gallery of the active Item
   * @param galleryPicture
   */
  uploadPictureToGallery(galleryPicture: File): Promise<number> {
    let productId: number = this.activeItem.id;
    var url =
      API_BASE_PRODUCTSERVICE +
      API_MAP["pictureGallery"]["POST"].replace(":entityId", productId + "");

    return new Promise((resolve, reject) => {
      this.fileUploadService.uploadFile(galleryPicture, url).subscribe(
        //success
        (x: HttpResponse<string>) => {
          resolve(parseInt(x.body));
        },
        //failure
        () => {
          reject(-1);
        }
      );
    });
  }

  deletePictureFromGallery(
    productId: number,
    pictureId: number
  ): Observable<HttpEvent<string>> {
    const url = SWARM_PIC_API + "gallery/delete/" + productId + "/" + pictureId;
    const req = new HttpRequest("GET", url, {
      headers: new HttpHeaders({}),
      reportProgress: true,
      responseType: "text",
    });
    return this.http.request(req);
  }

  updatePictureOrderOfGallery(
    pictureIds: number[],
    productId: number
  ): Observable<HttpEvent<any>> {
    const url = SWARM_PIC_API + "galleryPictureIds/" + productId;
    const req = new HttpRequest("POST", url, pictureIds, {
      //  params: params,
      headers: new HttpHeaders({}),
      reportProgress: true,
      responseType: "text",
    });
    return this.http.request(req);
  }

  getListOfGalleryPictureIds(productId: number): Observable<number[]> {
    return this.http.get<number[]>(
      API_BASE_PRODUCTSERVICE +
        API_MAP["pictureGallery"]["ALL"].replace(":entityId", productId + "")
    );
  }

  getProductPicture(productId): string {
    return (
      API_BASE_PRODUCTSERVICE +
      API_MAP["productPictures"]["GET"].replace(":entityId", productId + "")
    );
  }

  generateRandomNumber(): number {
    return Math.floor(Math.random() * 100 + 1);
  }

  getRelatedProductNameFromId(productId) {
    return this.collection.filter((product) => product.id === productId)[0]
      .name;
  }

  checkIfProductHasActiveVersion(product: ProductInterface): boolean {
    return product.productVariants.some((variant) =>
      variant.variantVersions.some(
        (version) => version.status === Status.ACTIVE
      )
    );
  }

  getMinimalProductList() {
    const url: string =
      API_BASE_PRODUCTSERVICE + API_MAP["products"]["MINIMAL"];
    return this.http.get<ProductMinimalInterface[]>(url);
  }

  getFilteredProductSlice(
    pageIndex: number,
    pageSize: number,
    organisationIds: number[],
    ptgIds: number[],
    attributeIds: number[],
    commonFeatureIds: number[],
    statusIds: number[],
    searchTerm: string,
    orderByOrganisation: SortMethod,
    ptgAttrIds: number[]
  ) {

    let pageRequestDto = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      organisationIds: organisationIds.filter((x) => x != null),
      ptgIds: ptgIds.filter((x) => x != null),
      attributeIds: attributeIds.filter((x) => x != null),
      commonFeatureIds: commonFeatureIds,
      statusIds: statusIds,
      searchTerm: searchTerm,
      sortMethod: orderByOrganisation,
      ptgAttrIds: ptgAttrIds
    };
    const json = JSON.stringify(pageRequestDto);
    return this.http.post(
      this.SWARM_API_BASE + this.SWARM_API_MAP["FILTER"],
      json,
      httpOptions
    );
  }

  filterProductsByStatus(
    products: ProductInterface[],
    status: boolean
  ): ProductInterface[] {
    if (status === false) {
      return products;
    }
    return products.filter(
      (product) =>
        product.productVariants.filter(
          (variant) =>
            variant.variantVersions.filter(
              (version) => version.status === Status.ACTIVE
            ).length > 0
        ).length > 0
    );
  }

  sendDemoRequest(requestDto: RequestInterface) {
    this.trackingService.eventEmitter("send_demo_request", "contact_request", "demo", this.userService.currentUser.userName);
    const json = JSON.stringify(requestDto);
    const url = this.SWARM_API_BASE + this.SWARM_API_MAP["DEMOREQUEST"];

    return this.http.post(url, json, httpOptions);
  }

  sendOfferRequest(requestDto: RequestInterface) {
    this.trackingService.eventEmitter("send_offer_request", "contact_request", "offer");
    const json = JSON.stringify(requestDto);
    const url = this.SWARM_API_BASE + this.SWARM_API_MAP["OFFERREQUEST"];

    return this.http.post(url, json, httpOptions);
  }

  sendListingRequest(requestDto: ListingRequestInterface) {
    this.trackingService.eventEmitter("send_listing_request", "contact_request", "listing");
    const json = JSON.stringify(requestDto);
    const url = this.SWARM_API_BASE + this.SWARM_API_MAP["LISTINGREQUEST"];

    return this.http.post(url, json, httpOptions);
  }

  /* Loads all ProductOverviews from an Organisation  */
  loadProductOverviewsOfOrganisation(organisationId: number){
    return new Observable<ProductOverview[]>(observer => {
      const url = this.SWARM_API_BASE + API_MAP["products"]["OF_ORGANISATION_OVERVIEW"].replace(":organisationId", organisationId.toString());

      this.http.get<ProductOverview[]>(url).subscribe(productOverviews => {
        if(productOverviews){
          observer.next(productOverviews);
        } else {
          observer.next(null);
        }
      })
    });
  }

  getBaustelleByProductId(productId: number): Observable<Baustelle> {
    const SWARM_BAU_API = "/proxy/api/v0/productservice/baustelle/";
    return this.http.get<Baustelle>('http://research.bimswarm.online' + SWARM_BAU_API + `${productId}`);
  }
}
