import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ProductInterface } from "src/app/model/products/product-interface";
import { ProductService } from "src/app/services/dataServices/product-service/productService";

@Component({
  selector: 'app-baustelle-product-overview',
  templateUrl: './baustelle-product-overview.component.html',
  styleUrls: ['./baustelle-product-overview.component.css']
})
export class BaustelleProductOverviewComponent implements OnInit {
  @Input()
  public productId: number;

  @Input()
  public serverId: any;
  @Output()
  public collapseOverview: EventEmitter<any> = new EventEmitter();

  public product: ProductInterface;

  public mockPTGs: string[] = ["IFC", "CAFM", "CDE"];
  public mockFeatures: { label: string; name: string }[] = [
    { label: "Zielgruppe", name: "Architekten" },
    { label: "BIM", name: "Open BIM" },
    { label: "Leistungsphase", name: "3,4,5" },
  ];
  public mockCertificates: string[] = ["IFC 2x3", "IFC4"];
  public mockInputFormats: string[] = [".dwg", ".dxf", ".gml"];
  public mockOutputFormats: string[] = [".dwg", ".dxf", ".ifc"];
  private apiUrl = 'http://research.bimswarm.online:1000/api/createContainer';

  constructor(private productService: ProductService, 
    private router: Router,
    private http: HttpClient) {}



  ngOnChanges() {
    this.productService.getProduct(this.productId).then((success) => {
      this.product = success;
      console.log(this.product);
    });
  }

  ngOnInit() {
    this.productService.getProduct(this.productId).then((success) => {
      this.product = success;
      console.log(this.product);
    });
  }

  /* Opens a new Tab with the ProductDetail Page of the currently selected Product */
  public navigateToProduct() {
    // Converts the route into a string that can be used
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/marktplatz/products/${this.product.id}`])
    );

    window.open(url, "_blank");
  }

  /* Calls the EventEmitter to close the Overview */
  public collapseProductOverview() {
    this.collapseOverview.emit();
  }

  public generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public createContainerService(endpointId: number, name: string, Image: any): Observable<any> {
    const url = `${this.apiUrl}?endpointId=${endpointId}&name=${name}&Image=${Image}`;
    return this.http.post<any>(url, { });
  }

  public createContainer() {
    this.productService.getBaustelleByProductId(407).subscribe(
      data =>
      { const endpointId = data.portainerId
        const baseName = this.product.sApiVersion;
        const randomId = this.generateRandomString(16);
        const name = `${baseName}-${randomId}`;
        const Image= this.product.sApiEndpoint;
        this.createContainerService(endpointId, name, Image)
          .subscribe(
            response => console.log('Container created:', response),
            error => console.error('Error creating container:', error)
          );
        });
    
  }
}
