<ng-container *ngIf="!loggedIn">
    <button *ngIf="!router.url.includes('/resetPassword')" mat-button (click)="listing()" style="margin-right: 1em">
      Listung anfragen
    </button>
    <button *ngIf="!router.url.includes('/resetPassword')" mat-button (click)="register()" style="margin-right: 1em">
      Registrieren
    </button>
    <button mat-button (click)="login()">Anmelden</button>
  </ng-container>
  <ng-container *ngIf="loggedIn && (activeProvider$ | async)">
    <button mat-button [matMenuTriggerFor]="menu">
        <!--Non-displayed mat-card is a workaround: Style-class "mat-card-avatar" is not loaded if left out-->
        <mat-card style="display: none"></mat-card>
      <img
        mat-card-avatar
        class="example-header-image"
        [src]="(activeProvider$ | async).img"
        style="margin-right: 0.5rem"
      />
      {{ (activeProvider$ | async).name }}
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <div>
        <div class="orgas">
          <ng-container *ngFor="let provider of (providers$ | async)">
            <div
              matRipple
              (click)="headerService.setActiveProvider(provider)"
              class="orga"
            >
              <img mat-card-avatar class="example-header-image" [src]="provider.img" />
              <span>
                {{ provider.name }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
      <button mat-menu-item (click)="openMyProducts()">
        <mat-icon>bookmark</mat-icon>
        Merkliste
      </button>
      <button *ngIf="this.userService.currentUser.hasComposerAccess" mat-menu-item (click)="openComposer()">
        <mat-icon>trip_origin</mat-icon>
        Composer
      </button>
      <button *ngIf="this.userService.currentUser.hasComposerAccess" mat-menu-item (click)="openGaiaX()">
        <mat-icon>trip_origin</mat-icon>
        GAIA-X Selbstbeschribung
      </button>
      <button mat-menu-item (click)="headerService.openSettings(activeProvider.organisationId)">
        <mat-icon>settings</mat-icon>
        Einstellungen
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        Abmelden
      </button>
    </mat-menu>
  </ng-container>
